<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-4 text-center col-lg-8">
          <a
            href="https://discord.com/invite/megaroleplay"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Discord
          </a>
          <a
            href="samp://s1.megaroleplay.net:7777"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Ip SAMP
          </a>
          <a
            href="https://megaroleplay.net"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Web
          </a>
          <a
            href="https://megaroleplay.net/login"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Tiktok
          </a>            
          <a
            href="https://instagram.com/ieztebann"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0">
            Youtube
          </a>
        </div>
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a
            href="https://instagram.com/ieztebann"
            target="_blank"
            class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a
            href="https://instagram.com/ieztebann"
            target="_blank"
            class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a
            href="https://instagram.com/ieztebann"
            target="_blank"
            class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-instagram"></span>
          </a>
          <a
            href="https://instagram.com/ieztebann"
            target="_blank"
            class="text-secondary me-xl-4 me-4">
            <span class="text-lg fab fa-github"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} Desarrollado por
            <a
              href="https://www.instagram.com/ieztebann/"
              class="font-weight-bold"
              target="_blank"
              >Xiao</a
            >
            &
            <a
              href="/"
              class="font-weight-bold"
              target="_blank"
              >Mega Roleplay</a
            >.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer"
};
</script>
